footer {
    background: var(--color-primary);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}

#light footer {
    background: linear-gradient(45deg, var(--color-primary), var(--color-primary-variant));
}

#dark footer a {
    color: var(--color-bg);
}

#light footer a {
    color: black;
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 4rem;
}

/* =============== MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    .permalinks {
        flex-direction: column;
        gap: 1.5rem;
    }
}