.experience__container {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.experience__item-image {
    border-radius: 50rem;
    overflow: hidden;
}

.experience__heading {
    margin-bottom: 0rem;
}

.experience__detail {
    margin-bottom: 3rem;
}

.experience__text {
    text-align: center;
}

.experience__title {
    text-align: center;
    color: var(--color-light);
}

.experience__duration {
    text-align: center;
    color: var(--color-light);
    margin-bottom: 1rem;
}

#dark .experience__item {
    background: linear-gradient(to right bottom, var(--color-bg-variant), var(--color-bg));
    padding: 2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

#light .experience__item {
    background: linear-gradient(45deg, var(--color-primary), var(--color-primary-variant));
    padding: 2rem;
    border-radius: 2rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


#dark .experience__item:hover {
    border-color: var(--color-primary-variant);
    backdrop-filter: blur(10px);
    background: transparent;
}

#dark .experience__item > h5 {
    font-size: 14.5px;
}

#light .experience__item > h5 {
    color: rgb(41, 41, 41);
    font-size: 14.5px;
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px) {
    .experience__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    .experience__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}