.container.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

#dark .contact__option {
    background: linear-gradient(to right bottom, var(--color-bg-variant), var(--color-bg));
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

#light .contact__option {
    background: linear-gradient(45deg, var(--color-primary), var(--color-primary-variant));
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#dark .contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    backdrop-filter: blur(10px);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

#dark .contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

#light .contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
    color: var(--color-primary-variant);
}

#light .contact__option a:hover {
    color: black;
}

/* ==================== FORM ======================= */
form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
    backdrop-filter: blur(7px);
}

#light input, textarea {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    color: black;
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    .container.contact__container {
        width: var(--container-width-sm);
    }
}