nav {
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
}

#light nav a {
    color: white;
}

nav a:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

nav a.active {
    background: var(--color-bg);
    color: var(--color-white);
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 480px) {
    nav {
        width: 80%;
        padding-bottom: 0.8rem;
        /* scale: 80%; */
        /* padding: 0.6rem 1.5rem; */
    }
    
    nav a {
        /* width: 80%; */
        /* scale: 80%; */
        padding: 0.6rem;
        font-size: 1.1rem;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 460px) {
    nav {
        width: 80%;
        padding-bottom: 0.8rem;
        /* scale: 80%; */
        /* padding: 0.6rem 1.5rem; */
    }
    
    nav a {
        /* width: 80%; */
        /* scale: 80%; */
        padding: 0.5rem;
        font-size: 0.9rem;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 400px) {
    nav {
        width: 80%;
        padding-bottom: 0.8rem;
        /* scale: 80%; */
        /* padding: 0.6rem 1.5rem; */
    }
    
    nav a {
        /* width: 80%; */
        /* scale: 80%; */
        padding: 0.4rem;
        font-size: 0.8rem;
    }
}