.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

#dark .portfolio__item {
    background: linear-gradient(to right bottom, var(--color-bg-variant), var(--color-bg));
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

#light .portfolio__item {
    /* background: linear-gradient(45deg, var(--color-primary), var(--color-primary-variant)); */
    border-radius: 2rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#light .portfolio__item-description {
    padding: 1rem;
}

#dark .portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
    backdrop-filter: blur(10px);
}

#dark .portfolio__item-header {
    border-radius: 1.5rem;
    overflow: hidden;
}

#light .portfolio__item-header {
    background: linear-gradient(45deg, var(--color-primary), var(--color-primary-variant));
    position: relative;
    padding: 30px 30px 40px;
    border-radius: 0 0 20% 0;
    overflow: hidden;
}

#light .portfolio__item-image {
    border-radius: 1rem;
    overflow: hidden;
}


#dark .portfolio__item h3 {
    margin: 1.2rem 0 0.3rem;
}

#light .portfolio__item h3 {
    margin: 1.2rem 0 0.3rem;
    color: black;
}

.portfolio__item h5 {
    color: var(--color-primary);
}

.portfolio__item small {
    font-size: 0.7rem;
    color: var(--color-light);
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin: 1rem 0 1rem;
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}