header {
    height: 100vh;
    padding-top: 3rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

#light .header__container {
    color: black;
}

#light .text-light {
    color: grey;
}

/* ================ ARROW ================ */
.arrow__image {
    display: flex;
    justify-content: flex-start;
    width:  80px;
    height: 80px;
    position: absolute;
    top: 1.6rem;
    left: 4.7rem;
}

#dark .arrow__image {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}

.change__theme {
    display: flex;
    width:  160px;
    height: 30px;
    position: absolute;
    top: 0.2rem;
    left: 4.7rem;
    rotate: 5deg;
}

#dark .change__theme {
    -webkit-filter: invert(1);
    filter: invert(1);
}

/* ================ CTA ================ */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ================ SOCIALS ================ */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 18rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ================ ME ================ */
.me {
    border: 0px solid transparent;
    background: linear-gradient(var(--color-primary), var(--color-bg));
    border-radius: 50rem;
    overflow: hidden;
    width: 30rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 15rem);
    margin-top: 4rem;
    padding: 0.8rem 0.8rem 0.8rem 0.8rem;
    backdrop-filter: blur(3px);
    /* transition: var(--transition); */
}

#light .me {
    background: linear-gradient(90deg, var(--color-primary), var(--color-primary-variant));
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.image {
    border-radius: 50rem;
    overflow: hidden;
}

.me:hover {
    border: 1px solid transparent;
    border-color: var(--color-primary-variant);
    backdrop-filter: blur(10px);
    background: transparent;
}

/* ================ SCROLL DOWN ================ */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 24rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* =============== MEDIA QUERIES (LIGHT MODE SWTICH ONLY) ================== */
@media screen and (max-width: 1600px) {
    .change__theme, .arrow__image {
        display: none;
    }
}

/* =============== MEDIA QUERIES (LAPTOPS) ================== */
@media screen and (max-height: 800px) {
    .me {
        width: 24rem;
        height: 24rem;
        left: calc(50% - 12rem);
    }
}

/* =============== MEDIA QUERIES (LAPTOPS SMALL) ================== */
@media screen and (max-height: 700px) {
    header {
        padding-top: 1rem;
        height: 130vh;
    }
    .me {
        width: 20rem;
        height: 20rem;
        left: calc(50% - 10rem);
    }
}

/* =============== MEDIA QUERIES (LAPTOPS TINY) ================== */
@media screen and (max-height: 470px) {
    header {
        height: 120vh;
    }
    .me {
        width: 14rem;
        height: 14rem;
        left: calc(50% - 7rem);
    }
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px) {
    header {
        height: 120vh;
    }
    .me {
        width: 20rem;
        height: 20rem;
        left: calc(50% - 10rem);
    }

    .arrow__image, .change__theme {
        display: none;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    header {
        height: 120vh;
    }

    .header__socials, .scroll__down {
        display: none;
    }

    .change__theme, .arrow__image {
        display: none;
    }
}