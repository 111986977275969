@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

#dark {
    /* ============== BLUE ============== */
    --color-bg: #000215;
    --color-bg-variant: #000e40;
    --color-primary: #2ea8ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-light: rgba(255, 255, 255, 0.6);
}

#light {
    /* ============== RED / YELLOW ============== */
    --color-bg: rgba(255, 0, 13, 0.623);
    --color-bg-variant: rgba(255, 0, 13, 0.623);
    --color-primary: #ff000d9f;
    --color-primary-variant: #ffd000;
    --color-light: rgb(110, 110, 110);
}

:root {
    --color-white: #fff;

    --transition: all 800ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
}

#light {
    background: rgb(250, 250, 250);
}


/* ================ GENERAL STYLES ================== */
.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 8rem;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}


a:hover {
    color: var(--color-white);
}

#light a {
    color: var(--color-primary);
}

#light a:hover {
    color: var(--color-primary-variant);
}

#dark .btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
    backdrop-filter: blur(4px);
}

#light .btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    /* border: 1px solid var(--color-primary); */
    transition: var(--transition);
    backdrop-filter: blur(4px);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#dark .btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

#light .btn:hover {
    color: black;
}

#dark .btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

#light .btn-primary {
    background: linear-gradient(45deg, var(--color-primary), var(--color-primary-variant));
    color: var(--color-white);
}

#light .btn-light:hover {
    color: var(--color-primary-variant);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ================ SWITCH ================ */
.switch {
    display: flex;
    left: 14rem;
    top: 9rem;
    z-index: 5;
    position: absolute;
}

#dark .switch {
    filter: drop-shadow(1px 1px 0 white)
        drop-shadow(0.5px -0.5px 0 white)
        drop-shadow(-0.5px 0.5px 0 white);
}

#light .switch {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.switch__icon-day {
    display: flex;
    justify-content: center;
    height: 100%;
    font-size: 15;
    width: 60%;
    margin-left: 15%;
}

.switch__icon-night {
    display: flex;
    justify-content: center;
    height: 100%;
    font-size: 15;
    width: 60%;
    margin-left: 15%;
}

/* =============== MEDIA QUERIES (LAPTOPS) ================== */
@media screen and (max-height: 800px) {

}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px) {
    .switch {
        left: 8rem;
    }
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    section > h2 {
        margin-top: 2rem;
    }
}