.skills__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

#dark .skills__container > div {
    background: linear-gradient(to right bottom, var(--color-bg-variant), var(--color-bg));
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

#light .skills__container > div {
    background: linear-gradient(35deg, var(--color-primary), var(--color-primary-variant));
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#dark .skills__container > div:hover {
    border-color: var(--color-primary-variant);
    backdrop-filter: blur(10px);
    background: transparent;
}

.skills__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.skills__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1rem;
    margin-left: 1rem;
}

.skills__details {
    display: flex;
    gap: 1rem;
}

#dark .skills__details-icon {
    margin-top: 6px;
    color: var(--color-primary);
}

#light .skills__details-icon {
    margin-top: 6px;
    color: var(--color-primary-variant);
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px) {
    .skills__container {
        grid-template-columns: 1fr;
    }

    .skills__container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .skills__content {
        padding: 1rem;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    .skills__container {
        gap: 1rem;
    }

    .skills__container > div {
        width: 100%;
        padding: 2rem 1rem;
    }
}