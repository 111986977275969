.education__header {
    color: var(--color-primary);
    margin-bottom: 0;
}

.education__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    scale: 80%;
}

#dark .education__box > div {
    background: linear-gradient(to right bottom, var(--color-bg-variant), var(--color-bg));
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

#light .education__box > div {
    background: linear-gradient(45deg, var(--color-primary), var(--color-primary-variant));
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    transition: var(--transition);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#dark .education__box > div:hover {
    border-color: var(--color-primary-variant);
    backdrop-filter: blur(10px);
    background: transparent;
}

.education__container > div h2 {
    text-align: center;
    margin: 1rem 0 1rem;
    color: var(--color-primary);
}

.education__info {
    text-align: center;
    margin-top: 2rem;
}

.education__info {
    font-size: 1.4rem;
}

#dark .education__info small {
    font-size: 1.1rem;
    color: var(--color-light);
}

#light .education__info small {
    font-size: 1.1rem;
    color: var(--color-white);
}

#light .education__school-image {
    filter: drop-shadow(4px 4px 0 white);
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px) {
    .education__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
    .education__box > div {
        padding: 2.4rem 2rem;
    }
    .education__school-image {
        margin-top: 5.5rem;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    .education__container {
        grid-template-columns: 1fr;
        gap: 0.4rem;
    }
    /* .education__container > div h2 {
        font-size: 1.3rem;
        margin-bottom: 0.6rem;
    }
    .education__container > div h3 {
        font-size: 1rem;
        margin-bottom: 0.6rem;
    }
    .education__container > div h4 {
        font-size: 0.9rem;
        margin-bottom: 0rem;
    } */

    .education__info {
        font-size: 80%;
        margin-top: 0.8rem;
    }

    .education__info h2 {
        font-size: 1.3rem;
        margin-bottom: 0.6rem;
    }
    
    .education__school-image {
        margin-top: 0.6rem;
    }
}